import React from "react"
import { PrimaryButton } from "../../elements/Buttons"
import styled from "styled-components"
import { Link } from "gatsby"
import { PrimaryTitle } from "../../elements/Titles"
import bgImg from "../../images/mountainBG.png"

const BackgroundSection = styled.div`
  background-color: #f8f8f8;
  background-image: url('${bgImg}');
  background-position: bottom 0 right -20%;
  background-repeat: no-repeat;
  background-size: 90%;
  position: relative;
`

const OutdoorViewSection = styled.section`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Paragraph = styled.p`
  font-size: 1.3rem;
  text-align: left;
  width: 60%;
`

function OutdoorSection() {
  return (
    <BackgroundSection>
      <OutdoorViewSection className="wrapper">
        <PrimaryTitle>
          Bringing the peaceful outdoors
          <br /> into your luxury home
        </PrimaryTitle>
        <Paragraph>
          Stunning views with gently rolling greens, clusters of mature trees,
          ponds, and wildlife. Calm, quiet evenings on your deck or patio.
        </Paragraph>
        <Paragraph>
          Carefree living in a quality, custom home. Peaceful neighborhood with
          well manicured landscaping.
        </Paragraph>
        <Paragraph>
          Close knit social community with various social activities. Charming
          clubhouse with dining, golf shop and bar.
        </Paragraph>

        <Link to="build">
          <PrimaryButton>Learn More</PrimaryButton>
        </Link>
      </OutdoorViewSection>
    </BackgroundSection>
  )
}

export default OutdoorSection
