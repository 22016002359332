import React from "react"
import { SecondaryTitle } from "../elements/Titles"
import styled from "styled-components"
import cabinetImg from "../images/kitchen-cabinets.jpg"

const FullWidthContainer = styled.div`
  background: #8c9bab;
`

const StyledSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #8c9bab;
  height: auto;
`
const SlantedImg = styled.div`
  flex-basis: 40%;

  @media (max-width: 955px) {
    display: none;
  }
`

const SlantedDiv = styled.div`
  @media (max-width: 580px) and (min-width: 360px) {
    h2 {
      font-size: 1.2rem;
    }
  }
`

function SlantedSection() {
  return (
    <FullWidthContainer>
      <StyledSection className="wrapper">
        <SlantedImg>
          <img src={cabinetImg} alt="Kitchen interior" />
        </SlantedImg>
        <SlantedDiv>
          <SecondaryTitle>
            You design it. We build it. <br />
            We design it. We build it.
          </SecondaryTitle>
        </SlantedDiv>
      </StyledSection>
    </FullWidthContainer>
  )
}

export default SlantedSection
