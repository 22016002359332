import React from "react"
import styled from "styled-components"
import GlobalStyles from "../../Global"
import { PrimaryButton } from "../../elements/Buttons"
import { Link } from "gatsby"

const ShowcaseTitle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fefefe;

  h1 {
    font-size: 2.1rem;
    text-transform: uppercase;
    text-align: center;
    padding: 5rem 0 2rem 0;
    width: 80%;
    margin: 0 auto;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    width: 60%;
    margin: 0 auto;
  }

  @media (max-width: 736px) {
    h1 {
      font-size: 1.8rem;

      padding: 3rem 0 2rem 0;
      width: 95%;
    }

    p {
      font-size: 1.1rem;
      width: 75%;
    }
  }

  @media (max-width: 320px) {
    h1 {
      font-size: 1.2rem;

      padding: 3rem 0 2rem 0;
      width: 95%;
    }

    p {
      font-size: 0.9rem;
      width: 75%;
    }
  }
`

function ShowcaseContent() {
  return (
    <ShowcaseTitle className="wrapper">
      <GlobalStyles />
      <h1>
        Whether you want to us to design your custom home or have a design of
        your own, we will be happy to discuss options with you.
      </h1>
      <p>
        We offer personalized consideration and communication each step of the
        way. We are happy to provide our portfolio of past customers, projects,
        and licenses.
      </p>
      <Link to="build">
        <PrimaryButton>Learn More</PrimaryButton>
      </Link>
    </ShowcaseTitle>
  )
}

export default ShowcaseContent
