import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import GlobalStyles from "../Global"
import Showcase from "../components/homepage/Showcase"
import OutdoorSection from "../components/homepage/OutdoorSection"
import SlantedSection from "../components/SlantedSection"
import Cta from "../components/homepage/Cta"

function IndexPage() {
  return (
    <Layout>
      <GlobalStyles />
      <SEO title="About Custom Alto Homes" />
      <Showcase />
      <OutdoorSection />
      <SlantedSection />
      <Cta />
    </Layout>
  )
}

export default IndexPage
