import React from "react"
import styled from "styled-components"
import { PrimaryTitle } from "../../elements/Titles"
import { PrimaryButton } from "../../elements/Buttons"
import { Link } from "gatsby"

const StyledSection = styled.section`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  flex-direction: wrap;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
`

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  width: 60%;
  text-align: center;
`

function Cta() {
  return (
    <StyledSection className="wrapper">
      <PrimaryTitle>Ready to build. Call us today.</PrimaryTitle>
      <Paragraph>
        We are in a unique position to offer stunning,
        <br /> high-end construction immediately.
      </Paragraph>

      <Link to="contact">
        <PrimaryButton>Contact Us</PrimaryButton>
      </Link>
    </StyledSection>
  )
}

export default Cta
